// Angular Core
import { Component, HostListener, ViewChild, OnInit } from "@angular/core";
import { FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
import { MatStepper } from "@angular/material/stepper";
import { MatSelectChange } from "@angular/material/select";
import { Overlay } from "@angular/cdk/overlay";
// Services
import {
  CheckDuplicateResponse,
  MotorQuotationDataService,
} from "../../../share/data-service/motor/motor-quotation.data.service";
import { AlertService } from "../../../share/service/alert.service";
import { MasterDataDataService } from "../../../share/data-service/master-data.data.service";
import { PartnerService } from "src/app/share/service/partner.service";
import { TranslateService } from "@ngx-translate/core";
// Interfaces
import { MotorQuotationInterface } from "../../../share/interface/motor-quotation.interface";
// Components
import { BeforeExpiredDialogComponent } from "../../../dashboard/before-expired-dialog/before-expired-dialog.component";
import { RemindMeDialogComponent } from "../../ui/remind-me-dialog/remind-me-dialog.component";

// Utilities
import * as moment from "moment";
import SwiperCore, { Pagination, Autoplay, Navigation } from "swiper";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { ValidatorFunction } from "../../../share/function/validator.function";
import {
  GENDER_TYPE,
  ID_TYPE,
  MARITAL_STATUS,
} from "src/app/share/constants/common.types";
import clevertap from "clevertap-web-sdk";
import { SubmitSuccessDialogComponent } from "src/app/dashboard/submit-success-dialog/submit-success-dialog.component";
import { lastValueFrom, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import {
  IAvailableBanners,
  IAvailableSalesCampaign,
} from "src/app/share/interface/motor.interface";
import { CAMPAIGN_TYPE } from "src/app/share/constants/campaign";
import {
  D2C_BANNER,
  D2C_MOBILE_BANNER,
  DEFAULT_CTA_BANNER,
} from "src/app/share/constants/banner";
import { ErrorDialogComponent } from "../../ui/error-dialog/error-dialog.component";
import { RequestStatusDialogComponent } from "../../ui/request-status-dialog/request-status-dialog.component";

SwiperCore.use([Pagination, Autoplay, Navigation]);
@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  partnerCode: string;
  currentLang: string;

  @ViewChild("stepper") stepper: MatStepper;
  vehicleFormGroup: FormGroup;
  custFormGroup: FormGroup;
  @ViewChild("vehicleForm") vehicleForm: NgForm;
  @ViewChild("custForm") custForm: NgForm;
  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    this.mobileView = width < 960;
  }

  idTypeOption = ID_TYPE;
  maritalStatusOption = MARITAL_STATUS;
  genderOption = GENDER_TYPE;

  countryList: string[] = [];
  filterCountryList: string[] = [];
  availableSalesCampaign: IAvailableSalesCampaign[] = [];
  availableBanners: IAvailableBanners[] = [];

  isCompany: boolean = false;
  isMalaysian: boolean = true;
  pdpaAgreement: boolean = false;
  clickedNext: boolean = false;
  isLoading: boolean = false;
  showSuccessGif: boolean = false;
  eHailingChecked: boolean = false;
  competitorLinkChecked: boolean = false;
  mobileView: boolean = false;
  quotationSubmitted: boolean = false;
  quotationInputData: MotorQuotationInterface;

  idType: Number = 1;
  utmCampaign: string;
  utmSource: string;
  utmMedium: string;
  utmContent: string;
  maxDob: Date = new Date();
  contactInfo: string;
  successGifType: string;
  validPostcodes: string[] = [];

  // banner images
  banners = [
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_1-en.png",
      show: true,
      alt: "car-insurance-flood-protection",
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_2-en.png",
      show: true,
      alt: "car-insurance-female-protection",
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_3-en.png",
      show: true,
      alt: "car-insurance-family-protection",
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_4-en.png",
      show: true,
      alt: "car-free-digital-road-tax",
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_5-en.png",
      show: true,
      alt: "car-insurance-shopback-paylater",
    },
    {
      lang: "en",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_6-en.png",
      show: true,
      alt: "",
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_1-bm.png",
      show: true,
      alt: "car-insurance-flood-protection",
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_2-bm.png",
      show: true,
      alt: "car-insurance-female-protection",
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_3-bm.png",
      show: true,
      alt: "car-insurance-family-protection",
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_4-bm.png",
      show: true,
      alt: "car-free-digital-road-tax",
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_5-bm.png",
      show: true,
      alt: "car-insurance-shopback-paylater",
    },
    {
      lang: "bm",
      src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/banner_6-bm.png",
      show: true,
      alt: "",
    },
  ];

  customBanner = {
    mainBanner: D2C_BANNER,
    mobileMainBanner: D2C_MOBILE_BANNER,
    ctaBanner: DEFAULT_CTA_BANNER,
    ctaUrl: "https://rebrand.ly/harga-insurans-lebih-rendah-policystreet",
  };

  idTypeList = [
    { id: 1, name: "common.icNo" },
    { id: 2, name: "common.foreigner" },
    { id: 3, name: "common.company" },
  ];

  typeOfInsuranceList = [
    // { id: "TP", name: "common.thirdPartyOnlyIns" },
    { id: "TPFT", name: "common.TPFT" },
    { id: "COM", name: "common.COM" },
  ];

  showWhatsappBtn: Boolean = true;
  hideWhatsappBtnList: string[] = ["wapcar", "carsome", "carlist"];
  isOfficeHour: boolean;

  uspList = [
    { id: 1, 
      icon: "/assets/icons/landing/usp1.svg", 
      title: "dashboard.sellingPoint1", 
      desc: "dashboard.sellingPoint1Desc" 
    },
    { id: 2, 
      icon: "/assets/icons/landing/usp2.svg", 
      title: "dashboard.sellingPoint2", 
      desc: "dashboard.sellingPoint2Desc" 
    },
    { id: 3, 
      icon: "/assets/icons/landing/usp3.svg", 
      title: "dashboard.sellingPoint3", 
      desc: "dashboard.sellingPoint3Desc" 
    },
    { id: 4, 
      icon: "/assets/icons/landing/usp4.svg", 
      title: "dashboard.sellingPoint4", 
      desc: "dashboard.sellingPoint4Desc" 
    },
  ];

  constructor(
    gtmService: GoogleTagManagerService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private masterDataDataService: MasterDataDataService,
    private motorQuotationDataService: MotorQuotationDataService,
    private motorDataService: MotorDataDataService,
    private partnerService: PartnerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    public overlay: Overlay,
  ) {
    gtmService.pushTag({
      event: "car_lp",
    });
    this.getParams();
    this.initForm();
  }

  ngOnInit() {
    if (window.screen.width < 960) {
      this.mobileView = true;
    }
    this.checkIfOfficeHour();
    this.getAvailableSalesCampaign();
    this.getAvailableBanners();
  }

  private async getParams() {
    let snapshotParam = this.activatedRoute.snapshot.queryParams;
    let url = window.location.href;
    let lang: string = snapshotParam.lang;
    this.partnerCode = this.partnerService.getPartnerCode();

    if (this.hideWhatsappBtnList.includes(this.partnerCode)) {
      this.showWhatsappBtn = false;
    }

    !this.partnerCode ? (this.partnerCode = "ps") : null;

    if (lang) this.translate.use(lang);
    this.utmSource = snapshotParam.utm_source;
    this.utmMedium = snapshotParam.utm_medium;
    this.utmCampaign = snapshotParam.utm_campaign;
    this.utmContent = snapshotParam.utm_content;

    if (!this.utmSource) {
      let searchEngineList = ["google", "yahoo", "bing"];
      let referrer = document.referrer;
      if (
        referrer != window.location.href ||
        referrer.includes("policystreet.com")
      ) {
        let searchEngine = searchEngineList.find((x) =>
          referrer.toLowerCase().includes(x),
        );
        if (searchEngine) {
          this.utmSource = searchEngine;
          this.utmMedium = "organic";
        } else {
          if (!referrer) this.utmSource = "direct";
          else {
            let url = new URL(referrer);
            this.utmSource = url.hostname;
            this.utmMedium = "referral";
          }
        }
      } else {
        this.utmSource = "direct";
      }
    }
  }

  private initForm() {
    this.vehicleFormGroup = new FormGroup({
      insuranceType: new FormControl("COM", Validators.required),
      carPlate: new FormControl(null, Validators.required),
      mobileNo: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      postcode: new FormControl(null),
      insuredType: new FormControl(null, Validators.required),
      includeRoadTax: new FormControl(false),
      eHailingUsed: new FormControl(false),
      competitorLink: new FormControl(false),
      competitorLinkValue: new FormControl(null),
    });
    this.vehicleFormGroup
      .get("competitorLinkValue")
      .setValidators((control: FormControl) => {
        if (this.vehicleFormGroup.get("competitorLink").value === true) {
          return Validators.required(control);
        } else {
          return null;
        }
      });
    this.vehicleFormGroup
      .get("competitorLink")
      .valueChanges.subscribe((value) => {
        if (!value) {
          this.vehicleFormGroup.get("competitorLinkValue").reset(); // Reset competitorLinkValue
          this.vehicleFormGroup.get("competitorLinkValue").clearValidators(); // Clear validators
          this.vehicleFormGroup
            .get("competitorLinkValue")
            .updateValueAndValidity(); // Update validation
        } else {
          this.vehicleFormGroup
            .get("competitorLinkValue")
            .setValidators(Validators.required); // Add validators
          this.vehicleFormGroup
            .get("competitorLinkValue")
            .updateValueAndValidity(); // Update validation
        }
      });
    this.custFormGroup = new FormGroup({
      identityNo: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      policIdentityNo: new FormControl(null),
      nationality: new FormControl(null, Validators.required),
      dob: new FormControl(),
      gender: new FormControl(null),
      maritalStatus: new FormControl(null, Validators.required),
      contactMethod: new FormControl(null),

      // Only for PS D2C
      psPostCode: new FormControl(null),
      psIdentityType: new FormControl("Malaysian", Validators.required),
      psIdentityNo: new FormControl(null),
      psPolicIdentityNo: new FormControl(null),
    });

    this.vehicleFormGroup.controls.insuranceType.setValue("COM");
    this.vehicleFormGroup.controls.insuredType.setValue("MV");
    this.custFormGroup.controls.maritalStatus.setValue("Single");
    this.custFormGroup.controls.contactMethod.setValue("Email");

    if (this.partnerCode !== "ps") {
      this.setMalaysian();
      this.vehicleFormGroup.controls.postcode.setValidators([
        Validators.required,
        (control: FormControl) => this.postcodeValidator(control),
      ]);
      this.vehicleFormGroup.controls.postcode.updateValueAndValidity();
    } else {
      // Validations for d2c
      this.setPSMalaysian();
      this.setPSStep2Validation();
    }

    // Get dob and gender on Affiliate
    this.custFormGroup.controls.identityNo.valueChanges.subscribe((value) => {
      if (
        this.custFormGroup.controls.identityNo.valid &&
        this.isMalaysian &&
        !this.isCompany
      ) {
        this.getGenderAndDate(value);
      }
    });

    // set dob and gender on PS
    this.custFormGroup.controls.psIdentityNo.valueChanges.subscribe(
      (value) => {
        if (
          this.custFormGroup.controls.psIdentityNo.valid &&
          this.isMalaysian &&
          !this.isCompany
        ) {
          this.getGenderAndDate(value);
        }
      },
    );

    // set validations for PS ID types
    this.custFormGroup.controls.psIdentityType.valueChanges.subscribe(
      (value) => {
        if (value == "Police/Army") {
          this.setPSPoliceArmy();
        }
        if (value == "Malaysian") {
          this.setPSMalaysian();
        } else if (value == "Company") {
          this.setPSIsCompany();
        } else if (value == "Foreigner") {
          this.setPSNonMalaysian();
        }
      },
    );

    // pre-fetch the list of postcodes
    this.masterDataDataService.selectAllPostcodes().subscribe({
      next: (x) => {
        this.validPostcodes = x;
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private postcodeValidator(control: FormControl) {
    const value = control.value;

    if (value != null && value.length === 5)
      return this.validPostcodes.includes(value) ? null : { customError: true };
    else return { customError: true };
  }

  private getGenderAndDate(value) {
    let date = moment(value.substring(0, 6), "YYMMDD");
    if (moment().isBefore(date)) {
      date.subtract(100, "year");
    }
    let lastChar = value.substring(11);
    this.custFormGroup.controls.dob.setValue(date.toDate());
    let gender = lastChar % 2 ? "M" : "F";
    this.custFormGroup.controls.gender.setValue(gender);
  }

  private setPSStep2Validation() {
    this.custFormGroup.controls.identityNo.setValidators([]);
    this.custFormGroup.controls.identityNo.updateValueAndValidity();

    this.custFormGroup.controls.psPostCode.setValidators([
      Validators.required,
      (control: FormControl) => this.postcodeValidator(control),
    ]);
    this.custFormGroup.controls.psPostCode.updateValueAndValidity();
  }

  private setNonCompanyValidation() {
    this.custFormGroup.controls.dob.setValidators([Validators.required]);
    this.custFormGroup.controls.gender.setValidators([Validators.required]);
    this.custFormGroup.controls.maritalStatus.setValidators([
      Validators.required,
    ]);

    this.custFormGroup.controls.dob.updateValueAndValidity();
    this.custFormGroup.controls.gender.updateValueAndValidity();
    this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
  }

  private setCompanyValidation() {
    this.custFormGroup.controls.dob.setValidators([]);
    this.custFormGroup.controls.gender.setValidators([]);
    this.custFormGroup.controls.maritalStatus.setValidators([]);
    this.custFormGroup.controls.nationality.setValidators([]);

    this.custFormGroup.controls.dob.updateValueAndValidity();
    this.custFormGroup.controls.gender.updateValueAndValidity();
    this.custFormGroup.controls.maritalStatus.updateValueAndValidity();
    this.custFormGroup.controls.nationality.updateValueAndValidity();
  }

  private getCountryList() {
    if (this.countryList.length != 0) {
      this.filterCountry();
      return;
    }
    this.masterDataDataService.getCountrySelection().subscribe({
      next: (x) => {
        this.countryList = x;
        this.filterCountry();
      },
      error: (err) => {
        this.alertService.openSnackBar(err.error);
      },
    });
  }

  private resetForm() {
    this.pdpaAgreement = false;
    this.vehicleFormGroup.reset();
    // this.vehicleForm.resetForm();
    this.custFormGroup.reset();
    // this.custForm.resetForm();

    this.vehicleFormGroup.controls.mobileNo.setValue(" ");
    this.vehicleFormGroup.controls.mobileNo.updateValueAndValidity();
    this.initForm();
    this.stepper.reset();
  }

  private setMalaysian() {
    this.isCompany = false;
    this.isMalaysian = true;
    this.custFormGroup.controls.identityNo.setValue(null);
    this.custFormGroup.controls.identityNo.setValidators([
      Validators.required,
      ValidatorFunction.nricValidator(),
    ]);
    this.custFormGroup.controls.dob.disable();
    this.custFormGroup.controls.gender.disable();
    this.custFormGroup.controls.identityNo.updateValueAndValidity();
    this.custFormGroup.controls.nationality.setValidators([]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();

    this.setNonCompanyValidation();
  }

  private setPSMalaysian() {
    this.isCompany = false;
    this.isMalaysian = true;

    this.custFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
      ValidatorFunction.nricValidator(),
    ]);
    this.custFormGroup.controls.psIdentityNo.updateValueAndValidity();
    this.custFormGroup.controls.psPolicIdentityNo.setValidators([]);
    this.custFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
    this.custFormGroup.controls.nationality.setValidators([]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();

    this.setNonCompanyValidation();
  }

  private setNonMalaysian() {
    this.isCompany = false;
    this.isMalaysian = false;
    this.getCountryList();
    this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
    this.custFormGroup.controls.nationality.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.dob.enable();
    this.custFormGroup.controls.gender.enable();
    this.custFormGroup.controls.nationality.updateValueAndValidity();
    this.custFormGroup.controls.identityNo.updateValueAndValidity();

    this.setNonCompanyValidation();
  }

  private setPSNonMalaysian() {
    this.isCompany = false;
    this.isMalaysian = false;
    this.getCountryList();

    this.custFormGroup.controls.nationality.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();
    this.custFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.psIdentityNo.updateValueAndValidity();
    this.custFormGroup.controls.psPolicIdentityNo.setValidators([]);
    this.custFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
    this.setNonCompanyValidation();
  }

  private setPSPoliceArmy() {
    this.isCompany = false;
    this.isMalaysian = true;

    this.custFormGroup.controls.psIdentityNo.setValue(null);
    this.custFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
      ValidatorFunction.nricValidator(),
    ]);
    this.custFormGroup.controls.psIdentityNo.updateValueAndValidity();

    this.custFormGroup.controls.nationality.setValidators([]);
    this.custFormGroup.controls.nationality.updateValueAndValidity();

    this.custFormGroup.controls.psPolicIdentityNo.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
  }

  private setIsCompany() {
    this.isCompany = true;
    this.isMalaysian = true;
    this.custFormGroup.controls.identityNo.setValidators([Validators.required]);
    this.custFormGroup.controls.identityNo.updateValueAndValidity();

    this.setCompanyValidation();
  }

  private setPSIsCompany() {
    this.isCompany = true;
    this.isMalaysian = true;

    this.custFormGroup.controls.psIdentityNo.setValidators([
      Validators.required,
    ]);
    this.custFormGroup.controls.psIdentityNo.updateValueAndValidity();
    this.custFormGroup.controls.psPolicIdentityNo.setValidators([]);
    this.custFormGroup.controls.psPolicIdentityNo.updateValueAndValidity();
    this.custFormGroup.controls.name.setValidators([]);
    this.custFormGroup.controls.name.updateValueAndValidity();
    this.setCompanyValidation();
  }

  idTypeChange(e: MatSelectChange) {
    let value = e.value;
    switch (value) {
      case 1:
        this.setMalaysian();
        break;
      case 2:
        this.setNonMalaysian();
        break;
      case 3:
        this.setIsCompany();
        break;
    }
  }

  getIdentityNoLabel() {
    if (this.isMalaysian && !this.isCompany) return "common.icNoLabel";
    else if (!this.isMalaysian) return "common.passportLabel";
    return "common.businessRegistrationNoLabel";
  }

  getNameLabel() {
    if (this.isMalaysian && !this.isCompany)
      return "common.fullNamePerICPassport";
    else if (!this.isMalaysian) return "common.namePerPassport";
    return "common.companyName";
  }

  getIdentityNoMasking() {
    if (this.isMalaysian && !this.isCompany) return "000000000000";
    return "";
  }

  filterCountry(val: string = null) {
    if (val) {
      let txt = val.trim().toLowerCase();
      this.filterCountryList = this.countryList.filter((x) =>
        x.trim().toLowerCase().startsWith(txt),
      );
    } else this.filterCountryList = Object.assign([], this.countryList);
  }

  eHailingUsedChange(e: boolean) {
    this.eHailingChecked = !this.eHailingChecked;
    if (e) {
      this.vehicleFormGroup.controls.includeRoadTax.setValue(false);
      this.vehicleFormGroup.controls.includeRoadTax.disable();
    } else {
      this.vehicleFormGroup.controls.includeRoadTax.enable();
    }
    this.vehicleFormGroup.controls.includeRoadTax.updateValueAndValidity();
  }

  // competitorLinkChange(e: boolean) {
  // 	this.competitorLinkChecked = !this.competitorLinkChecked;
  // 	if (e) {
  // 		this.vehicleFormGroup.controls.includeRoadTax.setValue(false);
  // 		this.vehicleFormGroup.controls.includeRoadTax.disable();
  // 	} else {
  // 		this.vehicleFormGroup.controls.includeRoadTax.enable();
  // 	}
  // 	this.vehicleFormGroup.controls.includeRoadTax.updateValueAndValidity();
  // }
  // private updateCustomBanner() {
  //   let newBanner = [];
  //   const campaigns = this.availableSalesCampaign || [];

  //   // Helper to check if a campaign is active
  //   const isActive = (code: string) => campaigns.some((c) => c.code === code);

  //   // Default banners and CTA
  //   this.customBanner.mainBanner = [...D2C_BANNER];
  //   this.customBanner.mobileMainBanner = [...D2C_MOBILE_BANNER];
  //   this.customBanner.ctaBanner = [...DEFAULT_CTA_BANNER];

  //   if (!campaigns.length) return; // No campaigns, use defaults

  //   // Filter out hidden banners based on active campaigns
  //   const filterBanners = (banners: any[]) =>
  //     banners.filter(
  //       (b) => !(b.hideIf && b.hideIf.some((code) => isActive(code))),
  //     );

  //   const filteredMainBanner = filterBanners(D2C_BANNER);
  //   const filteredMobileBanner = filterBanners(D2C_MOBILE_BANNER);

  //   // Handle roadtaxSept14 banner
  //   if (isActive(CAMPAIGN_TYPE.roadtaxSept14)) {
  //     newBanner.push(
  //       {
  //         lang: "en",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/14-sept-banner-en.gif",
  //         show: true,
  //         alt: "",
  //       },
  //       {
  //         lang: "bm",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/14-sept-banner-bm.gif",
  //         show: true,
  //         alt: "",
  //       },
  //     );
  //   }

  //   // Handle roadtaxSept19 banner
  //   if (isActive(CAMPAIGN_TYPE.roadtaxSept19)) {
  //     newBanner.push(
  //       {
  //         lang: "en",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-landing-banner-en.png",
  //         show: true,
  //         alt: "",
  //       },
  //       {
  //         lang: "bm",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-landing-banner-bm.png",
  //         show: true,
  //         alt: "",
  //       },
  //     );
  //     this.customBanner.ctaBanner = [
  //       {
  //         lang: "en",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-comparison-banner-en.png",
  //         show: true,
  //         alt: "",
  //       },
  //       {
  //         lang: "bm",
  //         src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/12-19-sept/epp-comparison-banner-bm.png",
  //         show: true,
  //         alt: "",
  //       },
  //     ];
  //   }

  //   // Combine filtered banners with new ones
  //   this.customBanner.mainBanner = [...newBanner, ...filteredMainBanner];
  //   this.customBanner.mobileMainBanner = [
  //     ...newBanner,
  //     ...filteredMobileBanner,
  //   ];
  // }

  private getAvailableBanners() {
    this.motorDataService.getAvailableBanners().subscribe({
      next: (x: IAvailableBanners[]) => {
        this.availableBanners = x.filter(
          (banner) => banner.status === "Active",
        );
      },
      error: () => {
        console.log("Failed to retrieve available campaigns.");
      },
    });
  }

  private getAvailableSalesCampaign() {
    this.motorDataService.getAvailableSalesCampaign().subscribe({
      next: (x: IAvailableSalesCampaign[]) => {
        this.availableSalesCampaign = x;
      },
      error: () => {
        console.log("Failed to retrieve available campaigns.");
      },
    });
  }

  async checkIfOfficeHour() {
    const response: string = await lastValueFrom(
      this.motorDataService.checkIsOfficeHour(),
    );
    this.isOfficeHour = response === "YES";
  }

  async submitQuotation(): Promise<void> {
    if (!this.vehicleFormGroup.valid || !this.custFormGroup.valid) return;
    let vehicleValue = this.vehicleFormGroup.getRawValue();
    let custValue = this.custFormGroup.getRawValue();
    if (vehicleValue.competitorLink) {
      this.utmSource = "CRM";
    }
    const d2c = this.partnerCode == "ps";
    this.successGifType = custValue.contactMethod;
    custValue.contactMethod == "Mobile"
      ? (this.contactInfo = vehicleValue.mobileNo)
      : (this.contactInfo = vehicleValue.email);

    let contactMethod =
      custValue.contactMethod == "Mobile"
        ? "Mobile (Whatsapp)"
        : custValue.contactMethod;

    let input: MotorQuotationInterface = {
      insuranceType: vehicleValue.insuranceType,
      vehicleNo: vehicleValue.carPlate,
      typeofSum: vehicleValue.insuredType,
      includeRoadTax: vehicleValue.includeRoadTax,
      eHailingUsed: vehicleValue.eHailingUsed,
      isExistsCompetitor: vehicleValue.competitorLink,
      competitorLink: vehicleValue.competitorLinkValue,
      isMalaysian: this.isMalaysian,
      isCompany: this.isCompany,
      identityNo: d2c ? custValue.psIdentityNo : custValue.identityNo,
      postcode: d2c ? custValue.psPostCode : vehicleValue.postcode,
      policIdentityNo: d2c
        ? custValue.psPolicIdentityNo
        : custValue.policIdentityNo,
      preferredChannel: contactMethod,
      name: custValue.name,
      nationality: custValue.nationality,
      dob: custValue.dob,
      gender: custValue.gender,
      maritalStatus: custValue.maritalStatus,
      mobileNo: vehicleValue.mobileNo,
      email: vehicleValue.email,
      language: this.translate.currentLang,
      utmSouce: this.utmSource,
      utmMedium: this.utmMedium,
      utmCampaign: this.utmCampaign,
      utmContent: this.utmContent,
      isRenewal: false,
      motorLinkId: null,
    };

    this.isLoading = true;

    const isDuplicateQuote = await this.checkDuplicateQuote(input);
    if (isDuplicateQuote) return;

    this.quotationInputData = input;
    this.quotationSubmitted = true;

    this.pushCleverTapLogin();
  }

  async checkDuplicateQuote(
    request: MotorQuotationInterface,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.motorQuotationDataService.checkDuplicateRequest(request).subscribe({
        next: (x: CheckDuplicateResponse) => {
          if (x.isExist === "Y") {
            this.isLoading = false;
            this.dialog.open(RequestStatusDialogComponent, {
              panelClass: "no-padding-border-radius-dialog",
              data: {
                content: request.vehicleNo,
              },
            });
            resolve(true);
          }

          resolve(false);
        },
        error: (_error: any) => {
          this.isLoading = false;
          resolve(true);
        },
      });
    });
  }

  whatsappNumber = 60182822320;
  entityName = "Policystreet";
  whatsappContent = "";
  backToHome() {
    window.location.href = "/";
  }
  whatsppRedirect() {
    const whatsappNumber = 60182822320;
    const entityName = "Policystreet";
    let whatsappContent = "";
    let lang = this.translate.currentLang == "en" ? "en" : "ms";
    whatsappContent =
      lang == "ms"
        ? `Hai ${entityName}, saya ingin tahu dengan lebih lanjut mengenai cukai jalan dan insurans kenderaan`
        : `Hi ${entityName}, I would like to know more about road tax and car insurance`;

    let url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      whatsappContent,
    )}&source=&data=&app_absent=&lang=${lang}`;

    window.open(url, "_blank");
  }
  checkCss() {
    this.clickedNext = true;
  }

  onHomepage() {
    this.contactInfo = "";
    this.successGifType = "";
    this.showSuccessGif = false;
  }

  // Function to disable all form controls in a given form group
  private disableFormControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).disable();
    });
  }

  // Function to enable all form controls in a given form group
  private enableFormControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      formGroup.get(key).enable();
    });
  }

  pushCleverTapLogin() {
    //CleverTap analytics
    let vehicleValue = this.vehicleFormGroup.getRawValue();
    let custValue = this.custFormGroup.getRawValue();

    const identityNo =
      custValue.psIdentityNo == null ? "NA" : custValue.psIdentityNo;
    const partnerCode =
      this.partnerCode == null || this.partnerCode.trim().toUpperCase() == "PS"
        ? "D2C"
        : this.partnerCode.charAt(0).toUpperCase() +
          this.partnerCode.slice(1).toLowerCase();

    const identity =
      identityNo +
      "_" +
      "Car" +
      "_" +
      partnerCode +
      "_" +
      vehicleValue.carPlate +
      "_" +
      vehicleValue.mobileNo.replace("+", "");

    clevertap.onUserLogin.push({
      Site: {
        Identity: identity, // String or number (same as phone number) this will be used as Clevertap Identity
        CT_PS_ID: identity, // String or number (same as phone number) this will be used as Clevertap Identity
        Name: custValue.name,
        Phone: vehicleValue.mobileNo,
        IdentityNo: custValue.psIdentityNo,
        Gender: custValue.gender,
        "Marital Status": custValue.maritalStatus,
        "Preferred Channel": custValue.contactMethod,
        "Quotation Link": null,
        "Address Line 1": null,
        "Address Line 2": null,
        Postcode: custValue.psPostCode,
        State: null,
        Country: null,
        City: null,
        Email: vehicleValue.email,
        "MSG-email": true, // Disable email notifications
        "MSG-push": true, // Enable push notifications
        "MSG-whatsapp": true, // Enable WhatsApp notifications
        Origin: null,
        "Car Make": null,
        "Car Model": null,
        "Vehicle No": vehicleValue.carPlate,
        NCD: null,
        Expired_Date: null,
        "Quotation Date": null,
        "Renewed Date": null,
        "Quotation Status": null,
        "Include Roadtax": null,
        "Excess Type": null,
        "Effective Date": null,
        EhailingUsed: vehicleValue.eHailingUsed,
        Package: null,
        "Add-Ons Added": null,
        "Add-ons Name": null,
        "Partner Name": null,
        "Total Revenue": null,
        "Type of Insurance": vehicleValue.insuranceType,
        "Insurer Name": null,
        "Payment Type": null,
        "Payment Status": null,
      },
    });
  }

  private handleSuccessSubmitQuotation(
    expiryDate: string,
    customerInput: MotorQuotationInterface,
  ): void {
    const dialogRef = this.dialog.open(SubmitSuccessDialogComponent, {
      panelClass: "success-dialog",
      data: { expiryDate, customerInput },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.backToHome();
    });
  }

  private handleTooEarlySubmitQuotation(expiryDate: string): void {
    const dialogRef = this.dialog.open(BeforeExpiredDialogComponent, {
      data: { expiryDate },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap((resp) => {
          if (!resp) {
            return of(true);
          }

          return this.dialog
            .open(RemindMeDialogComponent, { panelClass: "success-dialog" })
            .afterClosed();
        }),
      )
      .subscribe(() => this.backToHome());
  }
}
