<div class="container" [ngClass]="type === 'total' ? 'bold' : null">
  <div class="container__title">
    {{ title }}
    <ng-container *ngIf="titleBelow">
      <br />
      <span class="container__titleBelow">
        {{ titleBelow }}
      </span>
    </ng-container>
  </div>
  <div class="container__flex">
    <div class="zero-instalment" *ngIf="type == 'zeroInstalment'">
      {{ "common.zeroInstalment" | translate }}
    </div>
    <div class="zero-instalment" *ngIf="type == 'bnplSubsidized'">
      {{ "common.bnplSubsidized" | translate }}
    </div>
    <div
      class="container__bold"
      [ngClass]="
        type === 'discount'
          ? 'discount-color'
          : type == 'normal'
          ? 'no-bold'
          : null
      "
    >
      <span *ngIf="type == 'discount'">- </span>
      <span *ngIf="type == 'discount' || currency">RM </span>{{ value }}
    </div>
  </div>
</div>
