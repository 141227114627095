<div
  *ngIf="partnerCode === 'ps'"
  class="main-container"
  [ngStyle]="showSuccessGif ? { 'min-width': '0px', 'min-height': '0px' } : {}"
  [ngClass]="
    stepper?.selectedIndex === 1 && !isCompany ? 'main-container-step-2' : ''
  "
>
  <ng-container *ngIf="!quotationSubmitted">
    <div class="forms-container" *ngIf="!showSuccessGif">
      <div class="forms">
        <div
          class="forms__back-button"
          *ngIf="stepper.selectedIndex === 1"
          (click)="stepper.previous()"
          [style.pointer-events]="isLoading ? 'none' : 'auto'"
        >
          <img src="/assets/image/stepper-back-button.png" alt="" />
          <span> {{ "common.back" | translate }} </span>
        </div>
        <div class="forms__title">
          <span *ngIf="stepper.selectedIndex === 0">
            {{ "dashboard.titleNew" | translate }}
          </span>
          <span *ngIf="stepper.selectedIndex === 1">
            {{ "dashboard.title2New" | translate }}
          </span>
        </div>

        <mat-stepper #stepper linear>
          <mat-step [stepControl]="vehicleFormGroup">
            <form [formGroup]="vehicleFormGroup" #vehicleForm="ngForm">
              <!-- <mat-form-field appearance="outline">
								<mat-label>{{ "common.typeOfInsurance" | translate }}</mat-label>
								<mat-select
									disableOptionCentering
									formControlName="insuranceType"
									required
								>
									<mat-option
										*ngFor="let item of typeOfInsuranceList"
										[value]="item.id"
									>
										{{ item.name | translate }}
									</mat-option>
								</mat-select>
								<mat-error
									>{{ "common.typeOfInsurance" | translate }}
									{{
										vehicleFormGroup.controls.insuranceType.errors
											| inputError
											| translate
									}}</mat-error
								>
							</mat-form-field> -->

              <!-- <mat-icon
								class="tooltip-icon"
								[matMenuTriggerFor]="typeTooltip"
								#typeTrigger="matMenuTrigger"
								(mouseenter)="typeTrigger.openMenu()"
							>
								info
							</mat-icon> -->

              <!-- <mat-menu
								#typeTooltip="matMenu"
								[overlapTrigger]="false"
								class="tooltip-popover dashboard"
							>
								<span (mouseleave)="typeTrigger.closeMenu()">
									<div class="title">
										{{ "common.COM" | translate }}
									</div>
									{{ "common.comprehensiveInsDesc" | translate }}
									<br />
									<br />
									<div class="title">
										{{ "common.TPFT" | translate }}
									</div>
									{{ "common.fireTheftInsDesc" | translate }}
									<br /><br />
								<div class="title">{{ "common.TP" | translate }}</div>
								{{ "common.thirdPartyOnlyInsDesc" | translate }}
								</span>
							</mat-menu> -->
              <div class="cta-container">
                <div class="cta-banner">
                  <ng-container *ngIf="!mobileView">
                    <ng-container *ngFor="let banner of availableBanners">
                      <ng-container
                        *ngIf="
                          banner.refType.startsWith('desktop_long') &&
                          translate.currentLang == banner.language
                        "
                      >
                        <img [src]="banner.imgUrl" [alt]="banner.imgUrl" />
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="mobileView">
                    <ng-container *ngFor="let banner of availableBanners">
                      <ng-container
                        *ngIf="
                          banner.refType.startsWith('mobile_long') &&
                          translate.currentLang == banner.language
                        "
                      >
                        <img [src]="banner.imgUrl" [alt]="banner.imgUrl" />
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!availableBanners.length">
                    <ng-container *ngFor="let x of customBanner.ctaBanner">
                      <img
                        [src]="x.src"
                        [alt]="x.src"
                        *ngIf="translate.currentLang == x.lang"
                      />
                    </ng-container>
                  </ng-container>
                </div>
              </div>
              <mat-form-field appearance="outline" class="upperc-input">
                <mat-label>{{ "common.carPlateNo" | translate }}</mat-label>
                <input
                  matInput
                  required
                  formControlName="carPlate"
                  mask="A{255}"
                  [validation]="false"
                  autocomplete="off"
                />
              </mat-form-field>

              <!-- ## Affiliate Exclusive (PostCode in step 1) -->
              <!-- <ng-container *ngIf="partnerCode !== 'ps'">
								<mat-form-field appearance="outline">
									<mat-label>{{
										"common.residentialPostcode" | translate
										}}</mat-label>
									<input matInput required formControlName="postcode" mask="00000"
										autocomplete="postal-code" />
								</mat-form-field>
							</ng-container> -->
              <!-- ## End Affiliate Exclusive (PostCode in step 1) -->

              <!-- ## D2C Exclusive (Identity Type && IC/Passport field in step 1) -->
              <ng-container>
                <div class="forms__identity">
                  <div class="forms__identity__dropdown">
                    <mat-form-field appearance="outline">
                      <mat-select formControlName="psIdentityType">
                        <mat-option [value]="idTypeOption.malaysian">{{
                          "common.Malaysian" | translate
                        }}</mat-option>
                        <mat-option [value]="idTypeOption.company">{{
                          "common.Company" | translate
                        }}</mat-option>
                        <mat-option [value]="idTypeOption.foreigner">{{
                          "common.Foreigner" | translate
                        }}</mat-option>
                        <mat-option [value]="idTypeOption.police">{{
                          "common.Police/Army" | translate
                        }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="forms__identity__input">
                    <ng-container
                      [ngSwitch]="vehicleFormGroup.value.psIdentityType"
                    >
                      <ng-container *ngSwitchCase="idTypeOption.malaysian">
                        <mat-form-field appearance="outline">
                          <mat-label>
                            {{ "common.icNoLabel" | translate }}
                          </mat-label>
                          <input
                            matInput
                            formControlName="psIdentityNo"
                            [mask]="getIdentityNoMasking()"
                            autocomplete="off"
                            [maxLength]="12"
                          />
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngSwitchCase="idTypeOption.company">
                        <mat-form-field appearance="outline">
                          <mat-label>{{
                            "common.businessRegistrationNo" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="psIdentityNo"
                            autocomplete="off"
                          />
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngSwitchCase="idTypeOption.foreigner">
                        <mat-form-field appearance="outline">
                          <mat-label>{{
                            "common.passportLabel" | translate
                          }}</mat-label>
                          <input
                            matInput
                            formControlName="psIdentityNo"
                            autocomplete="off"
                          />
                        </mat-form-field>
                      </ng-container>
                      <ng-container *ngSwitchCase="idTypeOption.police">
                        <mat-form-field appearance="outline">
                          <mat-label>{{
                            "common.policeOrArmyIcNo" | translate
                          }}</mat-label>
                          <input matInput formControlName="psPolicIdentityNo" />
                        </mat-form-field>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <ng-container
                  *ngIf="
                    vehicleFormGroup.value.psIdentityType ===
                    idTypeOption.police
                  "
                >
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "common.icNoLabel" | translate }}</mat-label>
                    <input
                      matInput
                      formControlName="psIdentityNo"
                      [mask]="getIdentityNoMasking()"
                      autocomplete="off"
                      [maxLength]="12"
                    />
                  </mat-form-field>
                </ng-container>
                <div
                  class="pb-xs"
                  *ngIf="
                    (vehicleFormGroup.value.psIdentityType ===
                      idTypeOption.malaysian ||
                      vehicleFormGroup.value.psIdentityType ===
                        idTypeOption.police) &&
                    vehicleFormGroup.controls.psIdentityNo.valid
                  "
                >
                  <div class="color-info user-info-txt">
                    {{ "common.dob" | translate }}:
                    <span class="color-black"
                      >{{ custFormGroup.value.dob | date : "dd MMM yyyy" }}
                    </span>
                  </div>
                  <div class="color-info user-info-txt pv-xs">
                    {{ "common.gender" | translate }}:
                    <span class="color-black">{{
                      custFormGroup.value.gender === genderOption.male
                        ? ("common.M" | translate)
                        : custFormGroup.value.gender === genderOption.female
                        ? ("common.F" | translate)
                        : ""
                    }}</span>
                  </div>
                </div>
              </ng-container>

              <!-- <div>
						<span class="label-with-tooltip">
							<mat-label>{{
							"common.insuredType" | translate
							}}</mat-label>
							<mat-icon
							class="tooltip-icon"
							[matMenuTriggerFor]="valueTooltip"
							#valueTrigger="matMenuTrigger"
							(mouseenter)="valueTrigger.openMenu()"
							>
							info
							</mat-icon>
						</span>
						<section class="box-selection">
							<mat-radio-group
							class="b2c-radio"
							aria-label="Select an option"
							formControlName="insuredType"
							>
							<mat-radio-button value="MV" selected
								>{{ "common.MV" | translate }}
							</mat-radio-button>
							<mat-radio-button value="AV" *ngIf="vehicleFormGroup.controls.insuranceType.value !== 'TPFT'"
								>{{ "common.AV" | translate }}
							</mat-radio-button>
							</mat-radio-group>
						</section>
						</div> -->
              <!-- <section>
						<mat-checkbox formControlName="includeRoadTax">{{ 'common.includeRoadTax' |
							translate }}</mat-checkbox>
						</section> -->

              <div
                class="forms__checkbox-container"
                *ngIf="this.vehicleFormGroup.value.insuranceType !== 'TPFT'"
              >
                <mat-label
                  >{{ "common.carUsedForEHailing" | translate }}.
                </mat-label>
                <mat-radio-group
                  class="b2c-radio ehailing-radio"
                  formControlName="eHailingUsed"
                >
                  <mat-radio-button [value]="true"
                    >{{ "common.yes" | translate }}
                  </mat-radio-button>
                  <mat-radio-button [value]="false" selected
                    >{{ "common.no" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div
                class="forms__checkbox-container"
                *ngIf="this.vehicleFormGroup.value.insuranceType !== 'TPFT'"
              >
                <mat-label
                  >{{ "common.competitorQuotation" | translate }}
                </mat-label>
                <mat-radio-group
                  class="b2c-radio ehailing-radio"
                  formControlName="competitorLink"
                >
                  <mat-radio-button [value]="true"
                    >{{ "common.yes" | translate }}
                  </mat-radio-button>
                  <mat-radio-button [value]="false" selected
                    >{{ "common.no" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>

              <div
                *ngIf="vehicleFormGroup.get('competitorLink').value === true"
              >
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.insertCompetitorLink" | translate
                  }}</mat-label>
                  <input
                    matInput
                    required
                    formControlName="competitorLinkValue"
                    autocomplete="off"
                  />
                  <mat-error
                    *ngIf="
                      vehicleFormGroup.get('competitorLinkValue').invalid &&
                      vehicleFormGroup.get('competitorLinkValue').touched
                    "
                  >
                    Competitor Link is required
                  </mat-error>
                </mat-form-field>
                <span class="insert-text"
                  >{{ "common.insertText1" | translate
                  }}<span class="font-bold">{{
                    "common.insertText2" | translate
                  }}</span
                  >{{ "common.insertText3" | translate }}</span
                >
              </div>
              <button
                class="forms__next-btn btn--yellow"
                mat-flat-button
                matStepperNext
                [disabled]="!vehicleFormGroup.valid"
                (click)="checkCss()"
              >
                {{ "common.next" | translate }}
              </button>
            </form>
          </mat-step>
          <mat-step [stepControl]="custFormGroup">
            <form
              [formGroup]="custFormGroup"
              (ngSubmit)="submitQuotation()"
              #custForm="ngForm"
            >
              <!--detais for foreigner only  -->
              <div *ngIf="!isMalaysian">
                <mat-form-field appearance="outline">
                  <mat-label>{{ "common.nationality" | translate }}</mat-label>
                  <mat-select formControlName="nationality">
                    <mat-option>
                      <ngx-mat-select-search
                        [placeholderLabel]="'common.nationalityPH' | translate"
                        [ngModelOptions]="{ standalone: true }"
                        [searching]="!countryList.length"
                        noEntriesFoundLabel="{{
                          'common.nationalityXrecord' | translate
                        }}"
                        (ngModelChange)="filterCountry($event)"
                        ngModel
                        ngDefaultControl
                      >
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let item of filterCountryList"
                      [value]="item"
                    >
                      {{ item }}</mat-option
                    >
                  </mat-select>
                  <mat-error
                    >{{ "common.nationality" | translate }}
                    {{
                      custFormGroup.controls.nationality.errors
                        | inputError
                        | translate
                    }}</mat-error
                  >
                </mat-form-field>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>{{ getNameLabel() | translate }}</mat-label>
                <input matInput autocomplete="name" formControlName="name" />
              </mat-form-field>

              <!-- ## D2C Exclusive (PostCode in step 2) -->
              <ng-container>
                <mat-form-field appearance="outline">
                  <mat-label>{{
                    "common.residentialPostcode" | translate
                  }}</mat-label>
                  <input
                    matInput
                    required
                    formControlName="psPostCode"
                    mask="00000"
                    autocomplete="postal-code"
                  />
                  <mat-error
                    style="display: block !important"
                    [hidden]="
                      !custFormGroup.controls.psPostCode?.errors?.customError
                    "
                  >
                    {{ "error.invalidPostcode" | translate }}
                  </mat-error>
                </mat-form-field>

                <ng-container *ngIf="!isMalaysian">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ "common.dob" | translate }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="picker"
                      formControlName="dob"
                      readonly
                      (click)="picker.open()"
                      [max]="maxDob"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                      >{{ "common.dob" | translate }}
                      {{
                        custFormGroup.controls.dob.errors
                          | inputError
                          | translate
                      }}</mat-error
                    >
                  </mat-form-field>
                </ng-container>
              </ng-container>
              <!-- ## End D2C Exclusive (PostCode in step 2) -->

              <mat-form-field appearance="outline">
                <mat-label>{{ "common.mobileNo" | translate }}</mat-label>
                <ngx-mat-intl-tel-input
                  [enableSearch]="true"
                  [preferredCountries]="['my']"
                  formControlName="mobileNo"
                  [required]="true"
                >
                </ngx-mat-intl-tel-input>
                <mat-error
                  >{{ "common.mobileNo" | translate }}
                  {{
                    custFormGroup.controls.mobileNo.errors
                      | inputError
                      | translate
                  }}</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="outline" class="pb-sm">
                <mat-label>{{ "common.emailAddress" | translate }}</mat-label>
                <input matInput formControlName="email" autocomplete="email" />
                <mat-error
                  style="display: block !important"
                  [hidden]="!custFormGroup.controls.email?.errors?.email"
                >
                  {{ "error.invalidEmail" | translate }}</mat-error
                >
                <mat-error
                  >{{ "common.emailAddress" | translate }}
                  {{
                    custFormGroup.controls.email.errors | inputError | translate
                  }}</mat-error
                >
              </mat-form-field>

              <!-- ## D2C Exclusive -->
              <section class="box-selection" *ngIf="!isMalaysian">
                <mat-label>{{ "common.gender" | translate }}</mat-label>
                <mat-radio-group
                  class="b2c-radio"
                  aria-label="Select an option"
                  formControlName="gender"
                >
                  <mat-radio-button [value]="genderOption.male"
                    >{{ "common.M" | translate }}
                  </mat-radio-button>
                  <mat-radio-button [value]="genderOption.female"
                    >{{ "common.F" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error
                  >{{ "common.gender" | translate }}
                  {{
                    custFormGroup.controls.gender.errors
                      | inputError
                      | translate
                  }}</mat-error
                >
              </section>
              <!-- ## End D2C Exclusive -->

              <section class="box-selection" *ngIf="!isCompany">
                <mat-label>{{ "common.maritalStatus" | translate }}:</mat-label>
                <mat-radio-group
                  class="b2c-radio"
                  aria-label="Select an option"
                  formControlName="maritalStatus"
                >
                  <mat-radio-button [value]="maritalStatusOption.single"
                    >{{ "common.Single" | translate }}
                  </mat-radio-button>
                  <mat-radio-button [value]="maritalStatusOption.married"
                    >{{ "common.Married" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </section>

              <!-- ## D2C Exclusive (Preferred Channel) -->
              <section class="box-selection mt-sm">
                <mat-label
                  >{{ "common.preferredContact" | translate }}:</mat-label
                >
                <mat-radio-group
                  class="b2c-radio"
                  aria-label="Select an option"
                  formControlName="contactMethod"
                >
                  <mat-radio-button value="Email"
                    >{{ "common.email" | translate }}
                  </mat-radio-button>
                  <mat-radio-button value="Mobile"
                    >{{ "common.WhatsApp" | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </section>
              <!-- ## End D2C Exclusive (Preferred Channel) -->

              <section class="mt-sm">
                <mat-checkbox
                  [checked]="pdpaAgreement"
                  (change)="pdpaAgreement = !pdpaAgreement"
                  class="checkbox-align-top dashboardCheckbox"
                  [disabled]="isLoading"
                >
                  <span class="color-info">
                    {{ "common.pdpa" | translate }}
                  </span>

                  <a
                    [href]="'common.pdpaHref' | translate"
                    target="blank"
                    style="text-decoration: none; color: #000"
                    [ngClass]="pdpaAgreement ? 'secondary-color' : ''"
                  >
                    <span>
                      {{ "common.pdpaLink" | translate }}
                    </span>
                  </a>
                </mat-checkbox>
              </section>

              <div class="forms__submit-container">
                <button
                  class="forms__submit-container__btn btn--yellow"
                  mat-flat-button
                  [disabled]="
                    !vehicleFormGroup.valid ||
                    !custFormGroup.valid ||
                    !pdpaAgreement ||
                    isLoading
                  "
                >
                  <div class="">
                    {{ "common.findTheBestPrice" | translate }}
                  </div>
                  <mat-icon *ngIf="isLoading"
                    ><mat-spinner diameter="20"></mat-spinner
                  ></mat-icon>
                </button>
              </div>

              <!-- <div class="forms__accepted-payment-container">
                <span>{{ "dashboard.weAccept" | translate }} :</span>
                <img src="assets/image/accepeted-payment-2.png" alt="" />
              </div> -->
            </form>
          </mat-step>
        </mat-stepper>
      </div>
    </div>

    <div class="swiper-container" *ngIf="!showSuccessGif">
      <swiper
        class="multiple"
        [pagination]="true"
        [pagination]="{ clickable: true }"
        [slidesPerView]="1"
        [slidesPerGroup]="1"
        [grabCursor]="true"
        [spaceBetween]="0"
        [autoplay]="{
          delay: 8000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }"
      >
        <!-- Custom banner  -->
        <ng-container *ngIf="!mobileView">
          <ng-container *ngFor="let banner of availableBanners">
            <ng-template
              *ngIf="
                banner.refType.startsWith('desktop_square') &&
                translate.currentLang == banner.language
              "
              swiperSlide
            >
              <img swiperSlide [src]="banner.imgUrl" [alt]="banner.imgUrl" />
            </ng-template>
          </ng-container>
          <ng-container *ngFor="let x of customBanner.mainBanner">
            <ng-template
              swiperSlide
              *ngIf="
                !availableBanners.length && translate.currentLang == x.lang
              "
            >
              <img
                swiperSlide
                [src]="x.src"
                [alt]="x.src"
                *ngIf="translate.currentLang == x.lang"
              />
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="mobileView">
          <ng-container *ngFor="let banner of availableBanners">
            <ng-template
              *ngIf="
                banner.refType.startsWith('mobile_square') &&
                translate.currentLang == banner.language
              "
              swiperSlide
            >
              <img swiperSlide [src]="banner.imgUrl" [alt]="banner.imgUrl" />
            </ng-template>
          </ng-container>
          <ng-container *ngFor="let x of customBanner.mobileMainBanner">
            <ng-template
              *ngIf="
                !availableBanners.length && translate.currentLang == x.lang
              "
              swiperSlide
            >
              <img
                swiperSlide
                [src]="x.src"
                [alt]="x.src"
                *ngIf="translate.currentLang == x.lang"
              />
            </ng-template>
          </ng-container>
        </ng-container>

        <!-- Normal banners  -->
        <ng-container *ngFor="let banner of banners">
          <ng-template
            *ngIf="translate.currentLang === banner.lang && banner.show"
            swiperSlide
          >
            <img
              (error)="banner.show = false"
              [src]="banner.src"
              [alt]="banner.alt"
            />
          </ng-template>
        </ng-container>
      </swiper>
    </div>

    <div class="gif-loading-container" *ngIf="showSuccessGif">
      <div class="gif-loading-view">
        <img
          *ngIf="successGifType == 'Email'"
          src="/assets/gif/EMAIL--Searching-Insurer-For-You-19.gif"
          alt=""
        />
        <img
          *ngIf="successGifType == 'Mobile'"
          src="/assets/gif/WA--Searching-Insurer-For-You-19.gif"
          alt=""
        />
        <div class="gif-loading-view__title">
          {{ "common.findingTheBestPrice" | translate }}
        </div>
        <div class="gif-loading-view__body-text">
          <span *ngIf="successGifType == 'Email'">
            {{ "dashboard.preferredChannelEmail" | translate }}
          </span>
          <span *ngIf="successGifType == 'Mobile'">
            {{ "dashboard.preferredChannelMobile2" | translate }}
          </span>
          <br />
          <span style="font-weight: bold">{{ contactInfo }}</span>
          <br />
          {{ "dashboard.preferredChannelDuration" | translate }}
        </div>
        <button
          class="gif-loading-view__button btn--yellow"
          (click)="onHomepage()"
          mat-flat-button
        >
          {{ "common.homePage" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="quotationSubmitted">
    <app-loading-quotation
      [inputData]="quotationInputData"
    ></app-loading-quotation>
  </ng-container>
</div>
