<div class="payment-option-container" (click)="$event.stopPropagation()">
    <form [formGroup]="paymentFormGroup">    
        <mat-radio-group
            class="payment-radio"
            formControlName="paymentType"
        >
            <div class="payment-radio__btn">
                <mat-radio-button
                    [value]="paymentOptionFullPrice"
                    [checked]="paymentTypeVal == paymentOptionFullPrice && paymentOptionFPTypeVal == paymentOptionRazer"
                    class="fp-margin"
                    (change)="fpRazerSelected($event)"
                >
                    <div class="payment-radio__label">Credit/Debit/E-wallet</div>
                    <div class="payment-radio__bold">
                        RM {{ this.totalPriceBeforeCharges | number : "1.2-2" }}
                    </div>
                    <div *ngIf="paymentTypeVal != paymentOptionFullPrice || paymentOptionFPTypeVal == paymentOptionShopee">
                        <img src="/assets/icons/payments/full-price.png" alt="" class="payment-radio__btn__icon">
                    </div>
                </mat-radio-button>
                <mat-radio-group
                    class="payment-radio full-price"
                    *ngIf="paymentTypeVal == paymentOptionFullPrice && paymentOptionFPTypeVal == paymentOptionRazer"
                    formControlName="paymentChannel"
                >
                    <div class="ml-lg mb-sm mt-md">{{ "paymentOptions.paymentMethod" | translate }}</div>	
                    <mat-radio-button [checked]="true" [value]="paymentOptionVisa">
                        <div>
                            Visa / Mastercard
                        </div>
                     <img src="/assets/icons/payments/full-price-1.png" alt="" class="full-price__icon">
                    </mat-radio-button>
                    <mat-radio-button [checked]="false"  [value]="paymentOptionFpx">
                        <div>
                            Online Banking (FPX)
                        </div>
                        <img src="/assets/icons/payments/full-price-2.png" alt="" class="full-price__icon">
                    </mat-radio-button>
                    <mat-radio-button [checked]="false"  [value]="paymentOptionEwallet" *ngIf="allowEwallet">
                        <div>
                            e-Wallet 
                        </div>
                        <img src="/assets/icons/payments/full-price-3.png" alt="" class="full-price__icon">
                    </mat-radio-button>
                    <mat-form-field appearance="outline" *ngIf="paymentChannelVal == paymentOptionEwallet" class="e-wallet-form">
                        <mat-label>{{ "paymentOptions.selectEWallet" | translate }}</mat-label>
                        <mat-select
                            disableOptionCentering
                            required
                            formControlName="ewalletType"
                        >
                            <mat-option
                                *ngFor="let item of ewalletTypeListing"
                                [value]="item?.value"
                            >
                               <span class="ewallet-options">
                                    <img [src]="item?.img" alt="">
                                    {{ item?.name }}
                                </span> 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-radio-group>
            </div>
            <mat-radio-button
                [value]="paymentOptionFullPrice"
                [checked]="paymentTypeVal == paymentOptionFullPrice && paymentOptionFPTypeVal == paymentOptionShopee"
                class="payment-radio__btn"
                *ngIf="!onlyFullPayment"
                (change)="fpShopeeSelected($event)"
            >
                <div class="payment-radio__label">SPayLater / ShopeePay</div>
                <div class="mt-xs">
                    <span>
                        {{ "paymentOptions.ScanQRAndChooseInstalmentsUp" | translate }}
						<br />
                        {{ "paymentOptions.to12Months" | translate }}.
                    </span>
                </div>
                <div class="payment-radio__shopee-copy">
                    <img src="/assets/icons/payments/spaylater_spay.png" alt=""  class="shopee-icon" />
                </div>
                
            </mat-radio-button>
            <mat-radio-button
                [value]="paymentOptionShopback"
                [checked]="paymentTypeVal == paymentOptionShopback"
                class="payment-radio__btn"
                *ngIf="!onlyFullPayment"
            >
                <div class="payment-radio__label">{{ "common.shopback" | translate }}</div>
                <div class="mt-xs payment-radio__bold">
                    <span>RM {{ this.totalPriceBeforeCharges | number : "1.2-2" }}</span>
                </div>
                <div class="payment-radio__shopback-copy">
                    <span class="bold">{{ "common.shopbackPay" | translate }}&nbsp;</span>
                    {{ "common.shopbackGet" | translate }}&nbsp;
                    <span class="shopback-orange"> {{ "common.shopbackCashback" | translate }}</span>
                    <img src="/assets/icons/payments/shopback.png" alt=""  class="shopback-icon" />
                </div>
                
            </mat-radio-button>
            <mat-radio-button
                [value]="paymentOptionEPP"
                [checked]="this.paymentFormGroup?.value?.paymentOptionEPPType == paymentOptionEPP3Or6Or12Months"
                class="payment-radio__btn"
                *ngIf="showEPP3Or6Or12Months && !onlyFullPayment"
                (change)="epp3Or6Or12MonthsSelected($event)"
            >
                <div class="payment-radio__label">{{ "paymentOptions.eppDuration" | translate }}</div>
                <div class="mt-xs">
                    <span>{{ "paymentOptions.epp" | translate }}</span>
                    <span class="gap"></span>
                    <img src="/assets/icons/payments/rms.png" alt=""  class="payment-radio__btn__icon" />
                </div>
                <img src="/assets/icons/payments/visa-mastercard.png" alt=""  class="payment-radio__btn__icon" />
                <div class="payment-radio__btn__epp" *ngIf="this.paymentFormGroup?.value?.paymentOptionEPPType == paymentOptionEPP3Or6Or12Months">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "paymentOptions.cardIssuer" | translate }}</mat-label>
                        <mat-select
                            disableOptionCentering
                            formControlName="bank"
                            (selectionChange)="eppBankSelected($event.value)"
                        >
                            <mat-option
                                *ngFor="let item of epp3Or6Or12MonthsChargeDistinctList"
                                [value]="item?.bankName"
                                class="epp-options"
                            >
                                <span class="ewallet-options">
                                    <img [src]="item?.bankLogoUrl" alt="">
                                    {{ item?.bankName }}
                                </span>    
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="this.paymentFormGroup?.value?.bank">
                        <mat-label>{{ "paymentOptions.monthlyOption" | translate }}</mat-label>
                        <mat-select
                            disableOptionCentering
                            formControlName="installmentMonth"
                        >
                            <mat-option
                                *ngFor="let item of filterListBasedOnSelectedBanks(epp3Or6Or12MonthsChargeList)"
                                [value]="item?.installmentPeriod"
                                class="epp-options"
                            >
                                {{ getPaymentMonthLabel(item.installmentPeriod) | translate }}
								(<span class="epp-options__bold">
									RM {{ getTotalPriceInstallment(item.installmentPeriod) | number : "1.2-2" }}
								</span>
								{{ "common.perMonth" | translate }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-radio-button>
            <mat-radio-button
                [value]="paymentOptionEPP"
                [checked]="this.paymentFormGroup?.value?.paymentOptionEPPType == paymentOptionEPP6Or12Months"
                class="payment-radio__btn"
                *ngIf="showEPP6Or12Months && !onlyFullPayment"
                (change)="epp6or12MonthsSelected($event)"
            >
                <div class="payment-radio__label">{{ "paymentOptions.6or12months" | translate }}</div>
                <div class="mt-xs">
                    <span>{{ "paymentOptions.epp" | translate }}</span>
                    <span class="gap"></span>
                    <img src="/assets/icons/payments/payex.png" alt=""  class="payment-radio__btn__icon" />
                </div>
                <img src="/assets/icons/payments/visa-mastercard.png" alt=""  class="payment-radio__btn__icon" />
                <div class="payment-radio__btn__epp" *ngIf="this.paymentFormGroup?.value?.paymentOptionEPPType == paymentOptionEPP6Or12Months">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ "paymentOptions.cardIssuer" | translate }}</mat-label>
                        <mat-select
                            disableOptionCentering
                            formControlName="bank"
                            (selectionChange)="eppBankSelected($event.value)"
                        >
                            <mat-option
                                *ngFor="let item of epp6Or12MonthsChargeDistinctList"
                                [value]="item?.bankName"
                                class="epp-options"
                            >
                                <span class="ewallet-options">
                                    <img [src]="item?.bankLogoUrl" alt="">
                                    {{ item?.bankName }}
                                </span>    
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" *ngIf="this.paymentFormGroup?.value?.bank">
                        <mat-label>{{ "paymentOptions.monthlyOption" | translate }}</mat-label>
                        <mat-select
                            disableOptionCentering
                            formControlName="installmentMonth"
                        >
                            <mat-option
                                *ngFor="let item of filterListBasedOnSelectedBanks(epp6Or12MonthsChargeList)"
                                [value]="item?.installmentPeriod"
                                class="epp-options"
                            >
                                {{ getPaymentMonthLabel(item.installmentPeriod) | translate }}
								(<span class="epp-options__bold">
									RM {{ getTotalPriceInstallment(item.installmentPeriod) | number : "1.2-2" }}
								</span>
								{{ "common.perMonth" | translate }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-radio-button>
            <!-- Atome BNPL -->
            <mat-radio-button
                *ngIf="!onlyFullPayment"
                class="payment-radio__btn"
                [value]="paymentOptionBNPL"
                [checked]="paymentChannelVal == paymentOptionAtome">
                <div class="payment-radio__label">{{ "common.atome" | translate }}</div>
                <div class="payment-radio__label">{{ "common.atomeBNPL" | translate }}</div>
                <div>
                    <img src="/assets/icons/payments/AtomeBNPL.png" alt="" class="payment-radio__btn__icon" />
                </div>
         </mat-radio-button>
        </mat-radio-group>
    </form>
</div>