import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";



@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private basePath: string = `${environment.signalrHubUrl}`;
  private hubConnection: signalR.HubConnection
    public startConnection () : Observable<boolean> {
      this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)  // add this for diagnostic clues
      .withUrl(this.basePath, { 
        skipNegotiation: true,  // skipNegotiation as we specify WebSockets
        transport: signalR.HttpTransportType.WebSockets, // force WebSocket transport
      })
      .build();
      
      return new Observable<boolean>((observer) => {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection started');
          observer.next(true);
        })
        .catch(err => {
          console.log('Error while starting connection: ' + err);
          observer.next(false);
        })
      });
    }

    receiveUpdatedQuotation(): Observable<string> {
      return new Observable<string>((observer) => {
        this.hubConnection.on('ReceiveUpdatedQuotation', (message: string) => {
          observer.next(message);
        });
      });
    }

    public stopConnection() {
      this.hubConnection
      .stop()
      .then(() => console.log('Connection stopped'))
      .catch(err => console.log('Error while stopping connection: ' + err))
    }
    
}
