<div class="ftr">
  <div class="ktmb-note" *ngIf="partnerCode === 'ktmb' && stepperIndex !== undefined"><b>{{ "footer.importantNote" | translate }}</b>: {{ "footer.pleaseContact" | translate }}
  </div>
  <div class="ftr__container">
    <ng-container [ngSwitch]="isCustomFooter">
      <!-- Non Custom footer -->
      <ng-container *ngSwitchCase="false">
       <div class="ftr__D2C">
        <!-- Logo -->
        <div class="ftr__first">
          <div class="ftr__column">
            <div class="ftr__logo">
              <span> {{ "footer.poweredBy" | translate }} </span>
              <img
                src="{{ _commonInfo.policystreet.logoUrl }}"
                alt="policystreet"
              />
            </div>
            <!-- Address -->
            <div class="ftr__address">
              <span> {{ _commonInfo.policystreet.address }} </span>
            </div>
          </div>
          <div class="ftr__column contact">
            <!-- Contacts -->
            <div class="ftr__heading">{{ "footer.contactUs" | translate }}</div>
            <div class="ftr__contact">
              <img
                class="ftr__icon"
                src="./assets/icons/landing/whatsapp.svg"
                alt=""
              />
              <span> {{ _commonInfo.policystreet.whatsapp }} </span>
              <span>({{ "footer.whatsAppMessage" | translate }})</span>
            </div>

            <div class="ftr__contact">
              <img
                class="ftr__icon"
                src="./assets/icons/landing/email.svg"
                alt=""
              />
              <span> {{ _commonInfo.policystreet.email }} </span>
            </div>
          </div>

          <div class="ftr__column links">
            <div class="ftr__heading">{{ "footer.products" | translate }}</div>
            <a href="https://bit.ly/4dbFC7F" target="_blank">
              <span class="elementor-icon-list-text">{{ "footer.carInsurance" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/partners/shopee-damage-protection">
              <span class="elementor-icon-list-text">{{ "footer.damageProtection" | translate }}</span>
            </a>
            <a href="https://foodpanda-claims.policystreet.com/claims?_gl=1*zbnwq4*_gcl_au*MzA4MjMyOTM1LjE3Mjk3NjcwNTg.*_ga*MTc2MTU5MzA3OS4xNzIxMTAwODAy*_ga_DHR7ZCRRFK*MTczMDA5MDYxNi4zMC4xLjE3MzAwOTA2MzYuNDAuMC4w">
              <span class="elementor-icon-list-text">{{ "footer.ridersProtection" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/employee-benefits">
              <span class="elementor-icon-list-text">{{ "footer.employeeBenefits" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/partners">
              <span class="elementor-icon-list-text">{{ "footer.insuranceSolutions" | translate }}</span>
            </a>
          </div>

          <div class="ftr__column links">
            <div class="ftr__heading">{{ "footer.ourCompany" | translate }}</div>
            <a href="https://www.policystreet.com/about-us">
              <span class="elementor-icon-list-text">{{ "footer.aboutUs" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/careers/">
              <span class="elementor-icon-list-text">{{ "footer.careers" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/terms-business">
              <span class="elementor-icon-list-text">{{ "footer.termsOfBusiness" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/privacy-policy">
              <span class="elementor-icon-list-text">{{ "footer.privacyPolicy" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/pdpa">
              <span class="elementor-icon-list-text">{{ "footer.personalDataProtectionAct" | translate }}</span>
            </a>
            <a href="https://www.policystreet.com/wp-content/uploads/TC-Cheapest-Road-Tax-0-Interest.pdf" target="_blank">
              <span class="elementor-icon-list-text">{{ "footer.campaignTermsConditions" | translate }}</span>
            </a>
          </div>

          <div class="ftr__column social">
            <div class="ftr__heading">{{ "footer.social" | translate }}</div>
            <div class="ftr__social">
              <a href="https://www.tiktok.com/@policystreet?_t=8quiGusD38T&_r=1" target="_blank">
                <img src="./assets/icons/landing/tiktok.svg" alt="">
              </a>
              <a href="https://www.instagram.com/policystreet/" target="_blank">
                <img src="./assets/icons/landing/ig.svg" alt="">
              </a>
              <a href="https://www.facebook.com/share/dkbWQ4Tew1sv8X2V/?mibextid=LQQJ4d" target="_blank">
                <img src="./assets/icons/landing/fb.svg" alt="">
              </a>
              <a href="https://x.com/policystreetMY" target="_blank">
                <img src="./assets/icons/landing/x.svg" alt="">
              </a>
              <a href="https://www.linkedin.com/company/policystreet/" target="_blank">
                <img src="./assets/icons/landing/linkedin.svg" alt="">
              </a>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
        <ng-container *ngTemplateOutlet="payment_d2c"></ng-container>
       </div>
      </ng-container>

      <!-- Custom footer -->
      <ng-container *ngSwitchCase="true">
        <ng-container [ngSwitch]="partnerCode">
          <!-- Custom footer that's not carsome -->
          <ng-container *ngSwitchDefault>
            <!-- Logo -->
            <div class="ftr__column">
              <div class="ftr__custom__logo">
                <img
                  src="./assets/image/{{ partnerCode }}/{{
                    partnerCode
                  }}-logo.png"
                  alt=""
                />
                <span> {{ partnerInfo.copyright.value }} </span>
              </div>
            </div>
            <!-- Contacts -->
            <div class="ftr__column" *ngIf="partnerInfo.mobile.isDisplay">
              <div>
                <img
                  class="ftr__icon"
                  src="./assets/icons/footer/whatsapp-fill.png"
                  alt=""
                />
                <span> {{ partnerInfo.mobile.value }} </span>
              </div>
            </div>
            <div class="ftr__column" *ngIf="partnerInfo.email.isDisplay">
              <div>
                <img
                  class="ftr__icon"
                  src="./assets/icons/footer/email-fill.png"
                  alt=""
                />
                <span> {{ partnerInfo.email.value }} </span>
              </div>
            </div>
          </ng-container>
          <!-- Custom carsome footer -->
          <ng-container *ngSwitchCase="'carsome'">
            <div class="carsome__ftr">
              <div class="carsome__ftr__left">
                <img
                  src="./assets/image/{{ partnerCode }}/{{
                    partnerCode
                  }}-logo.png"
                  alt=""
                />
                <p class="copyright-text">{{ partnerInfo.copyright.value }}</p>
              </div>

              <mat-divider [vertical]="true"></mat-divider>

              <div class="carsome__ftr__right">
                <div class="carsome__ftr__right__question">
                  {{ "footer.haveAQuestion" | translate }}
                </div>

                <div class="carsome__ftr__right__email">
                  <div class="email-icon-container">
                    <mat-icon class="icon-display">mail_outlined</mat-icon>
                  </div>
                  <span class="email-content">
                    {{ partnerInfo.email.value }}
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
          <app-ktmb-footer *ngSwitchCase="'ktmb'" [info]="partnerInfo">
            <ng-container *ngTemplateOutlet="payment"></ng-container>
          </app-ktmb-footer>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-container [ngSwitch]="isCustomFooter">
    <ng-container *ngSwitchCase="false">
      <div class="ftr__bottom-d2c" [ngClass]="getFooterClasses()">
        <span>
          {{ "footer.licensed1" | translate }}
          <span>{{ _commonInfo.policystreet.regNo }}</span>
          {{ "footer.licensed2" | translate }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <div class="ftr__bottom" [ngClass]="getFooterClasses()">
        <span>
          {{ "footer.licensed1" | translate }}
          <span>{{ _commonInfo.policystreet.regNo }}</span>
          {{ "footer.licensed2" | translate }}
        </span>
      </div>
    </ng-container>
  </ng-container>
</div>


<ng-template #payment>
  <div class="ftr__second">
    <div class="ftr__column">
      <div>
        <span>{{ "footer.paymentMethod" | translate }}</span>
        <div>
          <img src="./assets/icons/bank_payment/visa.svg" alt="visa" />
          <img
            src="./assets/icons/bank_payment/mastercard.svg"
            alt="mastercard"
          />
          <img src="./assets/icons/bank_payment/fpx.svg" alt="fpx" />
          <img src="./assets/icons/bank_payment/tng.svg" alt="tng" />
          <img
            src="./assets/icons/bank_payment/grabpay.svg"
            alt="grabpay"
          />
          <img src="./assets/icons/bank_payment/boost.svg" alt="boost" />
          <img
            src="./assets/icons/bank_payment/shopback.svg"
            alt="shopback"
          />
          <img
            src="./assets/icons/bank_payment/spay.png"
            alt="spaylater_spay"
          />
          <img
            src="./assets/icons/bank_payment/spaylater.png"
            alt="spaylater_spay"
          />
        </div>
      </div>
    </div>

    <div class="ftr__column">
      <div>
        <span>{{ "footer.acceptedEppBanks" | translate }}</span>
        <div>
          <img
            src="./assets/icons/bank_payment/maybank.svg"
            alt="maybank"
          />
          <img src="./assets/icons/bank_payment/cimb.svg" alt="cimb" />
          <img src="./assets/icons/bank_payment/rhb.svg" alt="rhb" />
          <img src="./assets/icons/bank_payment/am.svg" alt="ambank" />
          <img src="./assets/icons/bank_payment/ocbc.svg" alt="ocbc" />
          <img
            src="./assets/icons/bank_payment/public.svg"
            alt="public"
          />
          <img src="./assets/icons/bank_payment/affin.svg" alt="affin" />
          <img src="./assets/icons/bank_payment/hlb.svg" alt="hlb" />
          <img src="./assets/icons/bank_payment/uob.svg" alt="uob" />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #payment_d2c>
  <div class="ftr__second">
    <div class="ftr__column">
      <div>
        <span>{{ "footer.paymentMethod" | translate }}</span>
        <div>
          <img
            src="./assets/icons/bank_payment/grabpay.svg"
            alt="grabpay"
          />
          <img src="./assets/icons/bank_payment/boost.svg" alt="boost" />
          <img
            src="./assets/icons/bank_payment/shopback.svg"
            alt="shopback"
          />
          <img
            src="./assets/icons/bank_payment/spaylater.png"
            alt="spaylater_spay"
          />
          <img src="./assets/icons/bank_payment/visa.svg" alt="visa" />
          <img
            src="./assets/icons/bank_payment/mastercard.svg"
            alt="mastercard"
          />
          <img src="./assets/icons/bank_payment/fpx.svg" alt="fpx" />
          <img src="./assets/icons/bank_payment/tng.svg" alt="tng" />
          <img
            src="./assets/icons/bank_payment/spay.png"
            alt="spaylater_spay"
          />
          <img
            src="./assets/icons/bank_payment/atome.png"
            alt="atome"
          />
        </div>
      </div>
    </div>

    <div class="ftr__column">
      <div>
        <span>{{ "footer.banksForZeroEpp" | translate }}</span>
        <div>
          <img
            src="./assets/icons/bank_payment/maybank.svg"
            alt="maybank"
          />
          <img src="./assets/icons/bank_payment/cimb.svg" alt="cimb" />
          <img src="./assets/icons/bank_payment/rhb.svg" alt="rhb" />
          <img src="./assets/icons/bank_payment/am.svg" alt="ambank" />
          <img src="./assets/icons/bank_payment/ocbc.svg" alt="ocbc" />
          <img
            src="./assets/icons/bank_payment/public.svg"
            alt="public"
          />
          <img src="./assets/icons/bank_payment/affin.svg" alt="affin" />
          <img src="./assets/icons/bank_payment/hlb.svg" alt="hlb" />
          <img src="./assets/icons/bank_payment/uob.svg" alt="uob" />
        </div>
      </div>
    </div>
  </div>
</ng-template>