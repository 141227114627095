import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { PAYMENT_OPTION_EPP, PAYMENT_OPTION_EPP_3_6_12_MONTHS, PAYMENT_OPTION_EPP_6_12_MONTHS, PAYMENT_OPTION_EWALLET, PAYMENT_OPTION_FP, PAYMENT_OPTION_FPX, PAYMENT_OPTION_PAYEX, PAYMENT_OPTION_RAZER, PAYMENT_OPTION_SHOPBACK, PAYMENT_OPTION_SHOPEE, PAYMENT_OPTION_VISA, PAYMENT_OPTION_ATOME } from 'src/app/share/constants/payment-options';
import { PAYMENT_EPP_TYPE, PAYMENT_EWALLET_TYPE } from 'src/app/share/constants/payment-options';
import { EPPChargeInterface } from 'src/app/share/interface/motor.interface';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})

export class PaymentOptionsComponent implements OnInit, OnChanges {
	paymentOptionShopback: string = PAYMENT_OPTION_SHOPBACK;
	paymentOptionFullPrice: string = PAYMENT_OPTION_FP;
	paymentOptionRazer: string = PAYMENT_OPTION_RAZER;
  paymentOptionPayex: string = PAYMENT_OPTION_PAYEX;
  paymentOptionEPP: string = PAYMENT_OPTION_EPP;
	paymentOptionShopee: string = PAYMENT_OPTION_SHOPEE;
  paymentOptionEPP3Or6Or12Months: string = PAYMENT_OPTION_EPP_3_6_12_MONTHS;
  paymentOptionEPP6Or12Months: string = PAYMENT_OPTION_EPP_6_12_MONTHS;
	paymentOptionVisa: string = PAYMENT_OPTION_VISA;
	paymentOptionEwallet: string = PAYMENT_OPTION_EWALLET;
	paymentOptionFpx: string = PAYMENT_OPTION_FPX;
  paymentOptionAtome: string = PAYMENT_OPTION_ATOME;
  ewalletTypeListing: any[] = PAYMENT_EWALLET_TYPE;
  eppTypeListing: any[] = PAYMENT_EPP_TYPE;
  totalPriceAfterCharge: number;
  epp3Or6Or12MonthsChargeList: EPPChargeInterface[] = [];
  epp6Or12MonthsChargeList: EPPChargeInterface[] = [];
  epp3Or6Or12MonthsChargeDistinctList: EPPChargeInterface[] = [];
  epp6Or12MonthsChargeDistinctList: EPPChargeInterface[] = [];
  epp3Or6Or12MonthsMinAmount: number;
  epp6Or12MonthsMinAmount: number;
  showEPP3Or6Or12Months: boolean = false;
  showEPP6Or12Months: boolean = false;
  totalPriceInstallment3: number;
  totalPriceInstallment6: number;
  totalPriceInstallment12: number;
  
  @Input() totalPrice: number;
  @Input() totalPriceBeforeCharges: number;
  @Input() bankCharge: { 3: number; 6: number; 12: number };
  @Input() paymentFormGroup: FormGroup;
  @Input() onlyFullPayment?: boolean = false;
  @Input() allowEwallet?: boolean = true;
  @Input() eppChargeList: EPPChargeInterface[];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.totalPriceInstallment3 = (this.totalPriceBeforeCharges + this.bankCharge?.["3"]) / 3 
    this.totalPriceInstallment6 = (this.totalPriceBeforeCharges + this.bankCharge?.["6"]) / 6 
    this.totalPriceInstallment12 = (this.totalPriceBeforeCharges + this.bankCharge?.["12"]) / 12

    if (this.eppChargeList && this.eppChargeList.length > 0) {
      this.epp3Or6Or12MonthsChargeList = this.eppChargeList
        .filter(eppCharge => 
          eppCharge.provider === this.paymentOptionRazer && 
          this.totalPriceBeforeCharges >= eppCharge.minAmount &&
          (eppCharge.maxAmount == null || this.totalPriceBeforeCharges <= eppCharge.maxAmount))
        .sort(function(a, b) {
            var textA = a.installmentPeriod;
            var textB = b.installmentPeriod;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      this.epp3Or6Or12MonthsMinAmount = Math.min(...this.epp3Or6Or12MonthsChargeList.map(eppCharge => eppCharge.minAmount));
      this.showEPP3Or6Or12Months = this.totalPriceBeforeCharges >= this.epp3Or6Or12MonthsMinAmount

      const groupByBankName1 = this.epp3Or6Or12MonthsChargeList.reduce((group, eppCharge) => {
        const { bankName } = eppCharge;
        group[bankName] = group[bankName] ?? [];
        group[bankName].push(eppCharge);
        return group;
      }, {});

      this.epp3Or6Or12MonthsChargeDistinctList = Object.values(groupByBankName1)
        .map(group => group[0])
        .sort(function(a, b) {
            var textA = a.bankName.toUpperCase();
            var textB = b.bankName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });

      this.epp6Or12MonthsChargeList = this.eppChargeList
        .filter(eppCharge => 
          eppCharge.provider === this.paymentOptionPayex && 
          this.totalPriceBeforeCharges >= eppCharge.minAmount&&
          (eppCharge.maxAmount == null || this.totalPriceBeforeCharges <= eppCharge.maxAmount))
        .sort(function(a, b) {
            var textA = a.installmentPeriod;
            var textB = b.installmentPeriod;
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
      this.epp6Or12MonthsMinAmount = Math.min(...this.epp6Or12MonthsChargeList.map(eppCharge => eppCharge.minAmount));
      this.showEPP6Or12Months = this.totalPriceBeforeCharges >= this.epp6Or12MonthsMinAmount
      
      const groupByBankName2 = this.epp6Or12MonthsChargeList.reduce((group, eppCharge) => {
        const { bankName } = eppCharge;
        group[bankName] = group[bankName] ?? [];
        group[bankName].push(eppCharge);
        return group;
      }, {});
  
      this.epp6Or12MonthsChargeDistinctList = Object.values(groupByBankName2)
        .map(group => group[0])
        .sort(function(a, b) {
            var textA = a.bankName.toUpperCase();
            var textB = b.bankName.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Do this to prevent ExpressionChangedAfterItHasBeenCheckedError
    if (changes.bankCharge && !changes.bankCharge.firstChange) {
        this.totalPriceInstallment3 = (this.totalPriceBeforeCharges + this.bankCharge?.["3"]) / 3 
        this.totalPriceInstallment6 = (this.totalPriceBeforeCharges + this.bankCharge?.["6"]) / 6 
        this.totalPriceInstallment12 = (this.totalPriceBeforeCharges + this.bankCharge?.["12"]) / 12
         
        if (this.eppChargeList && this.eppChargeList.length > 0) {
          this.showEPP3Or6Or12Months = this.totalPriceBeforeCharges >= this.epp3Or6Or12MonthsMinAmount
          this.showEPP6Or12Months = this.totalPriceBeforeCharges >= this.epp6Or12MonthsMinAmount
        }

        // Manually trigger change detection
        this.cdr.detectChanges();
    }
  }
  
  ngOnDestroy(): void {
    this.paymentFormGroup.patchValue({
      paymentType: "",
      paymentProvider: "",
      bank: "",
      installmentMonth: "",
      paymentOptionEPPType: ""
    });
  }
  
  fpShopeeSelected(event: MatRadioChange) {
    this.paymentFormGroup.patchValue({
      bank: "",
      installmentMonth: "",
      paymentOptionEPPType: "",
      ewalletType: "",
      paymentChannel: "ShopeePay",
      paymentOptionFPType: this.paymentOptionShopee
    });
  }

  fpRazerSelected(event: MatRadioChange) {
    this.paymentFormGroup.patchValue({
      paymentChannel: "credit",
      paymentOptionFPType: this.paymentOptionRazer
    });
  }

  epp3Or6Or12MonthsSelected(event: MatRadioChange) {
    this.paymentFormGroup.patchValue({
      paymentProvider: this.paymentOptionRazer,
      paymentOptionEPPType: this.paymentOptionEPP3Or6Or12Months
    });
  }

  epp6or12MonthsSelected(event: MatRadioChange) {
    this.paymentFormGroup.patchValue({
      paymentProvider: this.paymentOptionPayex,
      paymentOptionEPPType: this.paymentOptionEPP6Or12Months
    });
  }
  
  eppBankSelected(event: MatRadioChange) {
    this.paymentFormGroup.patchValue({
      installmentMonth: ""
    });
  }

  atomeSelected(event: MatRadioChange){
    this.paymentFormGroup.patchValue({
      paymentProvider: this.paymentOptionAtome,
      paymentChannel: this.paymentOptionAtome,
      paymentType: "BNPL",
      bank: "",
      installmentMonth: "",
      paymentOptionEPPType: "",
      ewalletType: "",
      paymentOptionFPType: ""
    });
  }

  filterListBasedOnSelectedBanks(list: any[]) {
		return list.filter((x) => x.bankName === this.paymentFormGroup.value.bank)
	}

  getPaymentMonthLabel(period: number) {
		if (period == 3) return "paymentOptions.3months";
		if (period == 6) return "paymentOptions.6months";
		if (period == 12) return "paymentOptions.12months";

		return "";
	}

  getTotalPriceInstallment(period: number) {
		if (period == 3) return this.totalPriceInstallment3;
		if (period == 6) return this.totalPriceInstallment6;
		if (period == 12) return this.totalPriceInstallment12;

		return 0;
	}

  get paymentTypeVal() {
    return this.paymentFormGroup?.value?.paymentType;
  }

  get paymentChannelVal() {
    return this.paymentFormGroup?.value?.paymentChannel;
  }

  get paymentOptionFPTypeVal() {
    return this.paymentFormGroup?.value?.paymentOptionFPType;
  }
}
