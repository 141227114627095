import { CAMPAIGN_TYPE } from "./campaign";

export const D2C_BANNER = [
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-en.png",
    show: true,
    alt: "",
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-bm.png",
    show: true,
    alt: "",
  },
];

export const D2C_MOBILE_BANNER = [
  // remove the below if passed 12 sept
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-mobile-en.png",
    show: true,
    alt: "",
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_main-mobile-bm.png",
    show: true,
    alt: "",
  },
];

export const DEFAULT_CTA_BANNER = [
  {
    lang: "en",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_mini-en.png",
    show: true,
    alt: "",
  },
  {
    lang: "bm",
    src: "https://storage.googleapis.com/ps-asset/motor/d2c/banners/quote_page/special/cta_banner_mini-bm.png",
    show: true,
    alt: "",
  },
];
