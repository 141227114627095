// Angular Core
import { Component, OnInit, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// Angular Material
import { MatDialog } from "@angular/material/dialog";
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from "@angular/material/bottom-sheet";
import { Clipboard } from "@angular/cdk/clipboard";
import { MatSnackBar } from "@angular/material/snack-bar";
// Services
import { AlertService } from "../../../share/service/alert.service";
import { MotorQuotationDataService } from "../../../share/data-service/motor/motor-quotation.data.service";
import { MotorDataDataService } from "src/app/share/data-service/motor.data.service";
import { TranslateService } from "@ngx-translate/core";
import { LoadingOverlayService } from "src/app/share/service/loading-overlay.service";
// Interfaces
import { MotorQuotationInterface } from "../../../share/interface/motor-quotation.interface";
// Components
import { CustomerDetailsDialogComponent } from "../../ui/customer-details-dialog/customer-details-dialog.component";
import { CustomerDetailsBottomsheetComponent } from "../../ui/customer-details-bottomsheet/customer-details-bottomsheet.component";
import clevertap from "clevertap-web-sdk";

@Component({
  selector: "app-renew-quotation",
  templateUrl: "./renew-quotation.component.html",
  styleUrls: ["./renew-quotation.component.scss"],
})
export class RenewQuotationComponent implements OnInit {
  @HostListener("window:resize", [])
  onResize() {
    var width = window.innerWidth;
    this.mobileView = width < 960;
  }

  // General variables
  id: string;
  quotationId: string;
  quotationData: any;

  // Utility variables
  reSubmitted: boolean = false;
  isUpdated: boolean = false;

  // Customer details
  idType: string;
  renewPage = {
    insuranceType: "",
    vehicleNo: "",
    postcode: "",
    typeofSum: "",
    includeRoadTax: false,
    eHailingUsed: false,
    isMalaysian: false,
    isCompany: false,
    identityNo: null,
    name: null,
    email: "",
    mobileNo: "",
    dob: new Date(),
    gender: "",
    maritalStatus: null,
    preferredChannel: null,
    policeIdentityNo: null,
    nationality: null,
    language: this.translate.currentLang,
    utmSource: null,
    utmMedium: null,
    utmCampaign: null,
    utmContent: null,
  };

  // View variables
  mobileView: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private motorDataDataService: MotorDataDataService,
    private motorQuotationDataService: MotorQuotationDataService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private loadingService: LoadingOverlayService,
  ) {}

  ngOnInit(): void {
    if (!history.state.quotationDetails) {
      this.id = this.activatedRoute.snapshot.params.id;
      this.quotationId = this.activatedRoute.snapshot.queryParams.id;

      if (!this.id) {
        window.location.href = "/";
      }
      this.getData();
    } else {
      this.quotationData = history.state.quotationDetails;
      this.reSubmitted = this.quotationData.expiredDetails.isResubmitted;
      this.initCustDetails();
    }
    if (window.screen.width < 960) {
      this.mobileView = true;
    }
    this.getParams();
  }

  private getData(): void {
    this.motorDataDataService.getQuotationById(this.id).subscribe({
      next: (x) => {
        if (x.hasOwnProperty("expiredDetails")) {
          this.quotationData = x;
          const expiredDetails = this.quotationData.expiredDetails;
          if (expiredDetails.hasOwnProperty("isResubmitted")) {
            this.reSubmitted = expiredDetails.isResubmitted;
          } else {
            window.location.href = "/";
          }
          this.initCustDetails();
        } else {
          window.location.href = "/";
        }
        return null;
      },
      error: (err) => {
        this.alertService.openSnackBar("Please contact policy street.");
        window.location.href = "/";
      },
    });
  }

  private getParams(): void {
    let snapshotParam = this.activatedRoute.snapshot.queryParams;
    this.id = this.activatedRoute.snapshot.params.id;
    this.quotationId = snapshotParam.id;
    let lang: string = snapshotParam.lang;

    if (lang) this.translate.use(lang);
    this.renewPage.utmSource = snapshotParam.utm_source;
    this.renewPage.utmMedium = snapshotParam.utm_medium;
    this.renewPage.utmCampaign = snapshotParam.utm_campaign;
    this.renewPage.utmContent = snapshotParam.utm_content;

    if (!this.renewPage.utmSource) {
      let searchEngineList = ["google", "yahoo", "bing"];
      let referrer = document.referrer;
      if (
        referrer != window.location.href ||
        referrer.includes("policystreet.com")
      ) {
        let searchEngine = searchEngineList.find((x) =>
          referrer.toLowerCase().includes(x),
        );
        if (searchEngine) {
          this.renewPage.utmSource = searchEngine;
          this.renewPage.utmMedium = "organic";
        } else {
          if (!referrer) this.renewPage.utmSource = "direct";
          else {
            let url = new URL(referrer);
            this.renewPage.utmSource = url.hostname;
            this.renewPage.utmMedium = "referral";
          }
        }
      } else {
        this.renewPage.utmSource = "direct";
      }
    }
  }

  private initCustDetails(): void {
    const expiredRequest = this.quotationData.expiredRequest;
    this.renewPage.typeofSum = "MV";
    this.renewPage.insuranceType = expiredRequest.insuranceType;
    this.renewPage.vehicleNo = expiredRequest.vehicleNo;
    this.renewPage.postcode = expiredRequest.postcode;
    this.renewPage.includeRoadTax = expiredRequest.includeRoadTax;
    this.renewPage.eHailingUsed = expiredRequest.eHailingUsed;
    this.renewPage.isMalaysian = expiredRequest.isMalaysian;
    this.renewPage.isCompany = expiredRequest.isCompany;
    this.renewPage.identityNo = expiredRequest.identityNo;
    this.renewPage.name = expiredRequest.name;
    this.renewPage.email = expiredRequest.email;
    this.renewPage.mobileNo = expiredRequest.mobileNo;
    this.renewPage.dob = new Date(expiredRequest.dob);
    this.renewPage.gender = expiredRequest.gender;
    this.renewPage.maritalStatus = expiredRequest.maritalStatus;
    this.renewPage.preferredChannel = expiredRequest.preferredChannel;
    this.renewPage.policeIdentityNo = expiredRequest.policIdentityNo;
    this.renewPage.utmSource = expiredRequest.utmSouce;
    this.renewPage.utmMedium = expiredRequest.utmMedium;
    this.renewPage.utmCampaign = expiredRequest.utmCampaign;
    this.renewPage.utmContent = expiredRequest.utmContent;
    this.renewPage.nationality = expiredRequest.nationality;

    this.setIdType();
  }

  setIdType(): void {
    switch (true) {
      case this.renewPage.isMalaysian &&
        !this.renewPage.isCompany &&
        this.renewPage.policeIdentityNo !== null:
        this.idType = "Police/Army";
        break;
      case this.renewPage.isMalaysian &&
        !this.renewPage.isCompany &&
        !this.renewPage.policeIdentityNo:
        this.idType = "Malaysian";
        break;
      case this.renewPage.isMalaysian && this.renewPage.isCompany:
        this.idType = "Company";
        break;
      case !this.renewPage.isMalaysian &&
        !this.renewPage.isCompany &&
        this.renewPage.nationality !== null:
        this.idType = "Foreigner";
        break;
      default:
        return;
    }
  }

  editDetails(): void {
    if (!this.mobileView) {
      const dialogRef = this.dialog.open(CustomerDetailsDialogComponent, {
        panelClass: "cust-details-dialog",
        data: {
          custDetails: this.renewPage,
          idType: this.idType,
        },
        height: "100%",
        width: "430px",
        position: { right: "0px", top: "0px" },
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.updateRenewPage(result.vehicle, result.customer);
        }
      });
    } else {
      const bottomSheetRef: MatBottomSheetRef<CustomerDetailsBottomsheetComponent> =
        this._bottomSheet.open(CustomerDetailsBottomsheetComponent, {
          panelClass: "cust-details-bottom-sheet",
          data: {
            custDetails: this.renewPage,
            idType: this.idType,
          },
        });
      bottomSheetRef.afterDismissed().subscribe((result) => {
        if (result) {
          this.updateRenewPage(result.vehicle, result.customer);
        }
      });
    }
  }

  updateRenewPage(vehicleDetails, custDetails): void {
    this.renewPage.insuranceType = vehicleDetails.insuranceType;
    this.renewPage.vehicleNo = vehicleDetails.carPlate;
    this.renewPage.postcode = custDetails.psPostCode;
    this.renewPage.typeofSum = vehicleDetails.insuredType;
    this.renewPage.includeRoadTax = vehicleDetails.includeRoadTax;
    this.renewPage.eHailingUsed = vehicleDetails.eHailingUsed;
    this.renewPage.identityNo = vehicleDetails.psIdentityNo;
    this.idType = vehicleDetails.psIdentityType;
    this.renewPage.policeIdentityNo = vehicleDetails.psPolicIdentityNo;
    this.renewPage.name = custDetails.name;
    this.renewPage.dob = custDetails.dob;
    this.renewPage.mobileNo = custDetails.mobileNo;
    this.renewPage.email = custDetails.email;
    this.renewPage.maritalStatus = custDetails.maritalStatus;
    this.renewPage.preferredChannel = custDetails.contactMethod;
    this.renewPage.gender = custDetails.gender;
    this.renewPage.nationality = custDetails.nationality;

    this.isUpdated = true;
    this.setIdentity();
  }

  setIdentity(): void {
    switch (this.idType) {
      case "Police/Army":
        this.renewPage.isCompany = false;
        this.renewPage.isMalaysian = true;
        this.renewPage.nationality = null;
        break;
      case "Malaysian":
        this.renewPage.isCompany = false;
        this.renewPage.isMalaysian = true;
        this.renewPage.nationality = null;
        this.renewPage.policeIdentityNo = null;
        break;
      case "Company":
        this.renewPage.isCompany = true;
        this.renewPage.isMalaysian = true;
        this.renewPage.nationality = null;
        this.renewPage.policeIdentityNo = null;
        break;
      case "Foreigner":
        this.renewPage.isCompany = false;
        this.renewPage.isMalaysian = false;
        this.renewPage.policeIdentityNo = null;
        break;
      default:
    }
  }

  copyToClipboardAndShowSnackbar(value: string): void {
    this.clipboard.copy(value);
    this.snackBar.open("Email copied to clipboard!", "Close", {
      duration: 2000,
    });
  }

  submit(): void {
    this.loadingService.setLoading(true);

    let contactMethod =
      this.renewPage.preferredChannel == "Mobile"
        ? "Mobile (Whatsapp)"
        : this.renewPage.preferredChannel;

    let input: MotorQuotationInterface = {
      insuranceType: this.renewPage.insuranceType,
      vehicleNo: this.renewPage.vehicleNo,
      typeofSum: this.renewPage.typeofSum,
      includeRoadTax: this.renewPage.includeRoadTax,
      eHailingUsed: this.renewPage.eHailingUsed,
      isMalaysian: this.renewPage.isMalaysian,
      isCompany: this.renewPage.isCompany,
      identityNo: this.renewPage.identityNo,
      postcode: this.renewPage.postcode,
      policIdentityNo: this.renewPage.policeIdentityNo,
      preferredChannel: contactMethod,
      name: this.renewPage.name,
      nationality: this.renewPage.nationality,
      dob: this.renewPage.dob,
      gender: this.renewPage.gender,
      maritalStatus: this.renewPage.maritalStatus,
      mobileNo: this.renewPage.mobileNo,
      email: this.renewPage.email,
      language: this.translate.currentLang,
      utmSouce: this.renewPage.utmSource,
      utmMedium: this.renewPage.utmMedium,
      utmCampaign: this.renewPage.utmCampaign,
      utmContent: this.renewPage.utmContent,
      isRenewal: true,
      motorLinkId: this.id,
    };

    this.motorQuotationDataService.submit(input).subscribe({
      next: (x) => {
        if (this.isUpdated) this.pushCleverTapLogin();
        this.reSubmitted = true;
        window.scrollTo(0, 0);
        this.loadingService.setLoading(false);
      },
      error: (err) => {
        this.reSubmitted = true;
        window.scrollTo(0, 0);
        this.loadingService.setLoading(false);
        this.alertService.openSnackBar("Please contact policy street.");
      },
    });
  }

  pushCleverTapLogin() {
    //CleverTap profile login
    let input = this.renewPage;
    const identityNo = input.identityNo == null ? "NA" : input.identityNo;
    const identity = identityNo + "_" + "Car" + "_" + "D2C_" + input.vehicleNo + "_" + input.mobileNo.replace('+', '');

    clevertap.onUserLogin.push({
      Site: {
        Identity: identity, // String or number (same as phone number) this will be used as Clevertap Identity
        CT_PS_ID: identity, // String or number (same as phone number) this will be used as Clevertap Identity
        Name: input.name,
        Email: input.email,
        Phone: input.mobileNo,
        IdentityNo: input.identityNo,
        Gender: input.gender,
        DOB: input.dob,
        "Vehicle No": input.vehicleNo,
        "Marital Status": input.maritalStatus,
        "Preferred Channel": input.preferredChannel,
        // controls whether the user will be sent email, push etc.
        "MSG-email": true, // Disable email notifications
        "MSG-push": true, // Enable push notifications
        "MSG-sms": true, // Enable sms notifications
        "MSG-whatsapp": true, // Enable WhatsApp notifications
      },
    });
  }
}
