import { MotorQuotationInterface } from "./../../interface/motor-quotation.interface";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MotorQuotationDataService {
  private basePath: string = `${environment.url}/api/Microsite/`;

  constructor(private http: HttpClient) {}

  submit(request: MotorQuotationInterface) {
    return this.http.post<MotorQuotationResponse>(
      `${this.basePath}Submit`,
      request,
    );
  }

  submitExtraProduct(id: string, typeOfSum: string, idList: string[]) {
    return this.http.post(`${this.basePath}SubmitExtraProduct/${id}`, {
      typeOfSum,
      selectedMotorProductIds: idList,
    });
  }

  checkDuplicateRequest(request: MotorQuotationInterface) {
    return this.http.post<CheckDuplicateResponse>(
      `${this.basePath}CheckDuplicateRequest`,
      request,
    );
  }
}

interface MotorQuotationResponse {
  isSuccess: boolean;
  isVehicleOverage: boolean;
  isEhailing: boolean;
  data: string;
}

export interface CheckDuplicateResponse {
  isExist: "Y" | "N";
}
